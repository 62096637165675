import React from 'react'
import { Link } from 'react-scroll'
import Picture from '../../../components/Picture'
import bannerTopoImg from '../../../assets/images/tecnologias/banner-topo.jpg'
import bannerTopoMobileImg from '../../../assets/images/tecnologias/banner-topo-mobile.png'

import * as S from './styles'

const BannerTopo = () => (
  <S.BannerTopo>
    <S.Container>
      <S.DescriptionWrap>
        <h1>Tecnologias</h1>
        <strong>Running</strong>
        <Link to="features" spy={true} smooth={true}>
          Saiba mais
        </Link>
      </S.DescriptionWrap>
    </S.Container>
    <Picture
      xs={bannerTopoMobileImg}
      md={bannerTopoImg}
      lg={bannerTopoImg}
      alt="Metaspeed"
    />
  </S.BannerTopo>
)

export default BannerTopo
