import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styles'

const ButtonFeature = ({ children, ...props }) => (
  <S.ButtonFeature {...props}>{children}</S.ButtonFeature>
)

ButtonFeature.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ButtonFeature
