import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1000px;
  margin: auto;
  @media (min-width: 1024px) {
    position: relative;
  }
`

export const BannerTopo = styled.div`
  position: relative;
`
export const DescriptionWrap = styled.div`
  position: absolute;
  top: 65px;
  right: 0;
  z-index: 1;
  font-family: 'ASICSFont3.0-Italic';
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  strong {
    color: var(--primary-color);
    font-size: 42px;
    line-height: 57px;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: 'ASICSFont3.0-BoldItalic';
    font-weight: bold;
    @media (min-width: 1024px) and (max-width: 1366px) {
      font-size: 35px;
      line-height: 45px;
    }
    @media (max-width: 767px) {
      font-size: 25px;
      line-height: normal;
    }
  }

  h1 {
    font-size: 82px;
    color: var(--primary-color);
    letter-spacing: -0.5px;
    font-weight: 400;
    line-height: 75px;
    font-weight: 400;
    @media (min-width: 1700px) {
      line-height: 110px;
      font-size: 113px;
    }
  }

  a {
    display: none;
    background: var(--primary-color);
    padding: 7px 30px 5px;
    border-radius: 50px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-family: 'ASICSFont3.0-Italic';
    font-weight: bold;
    letter-spacing: 2px;
    &:hover {
      background: #b9411d;
      color: #fff;
    }
  }

  @media (max-width: 767px) {
    top: auto;
    right: 15px;
    bottom: 195px;
    p {
      font-size: 23px;
      line-height: normal;
    }
    h1 {
      font-size: 34px;
      line-height: 25px;
    }

    a {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    top: auto;
    left: 40px;
    bottom: 120px;
  }

  @media (min-width: 990px) and (max-width: 1024px) {
    left: 20px;
    p {
      font-size: 30px;
      line-height: 45px;
    }

    h1 {
      font-size: 45px;
    }
  }

  @media (min-width: 1700px) {
    top: 170px;
  }
`
