import React, { useEffect } from 'react'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import BannerTopo from '../../components/Tecnologias/banner-topo'
import Features from '../../components/Tecnologias/Features'
import FormNewsletter from '../../components/Newsletter'
import { ditoTrack } from '../../helpers/dito'

import * as S from './styles'

import {
  gel,
  amplifoam,
  flytefoam,
  flytefoamPropel,
  flytefoamBlast,
  flytefoamBlast02,
  flytefoamBlastTurbo,
  guideDance,
  guideSole,
  duomax,
  litetruss,
  trusstic,
  ahar,
  impactGuidance,
  iconVelocidade,
  iconAhar,
  iconAmplifoam,
  iconConforto,
  iconDuomax,
  iconEconomiaEnergia,
  iconEstabilidade,
  iconFFBlast,
  iconFFBlastPlus,
  iconFFBlastTurbo,
  iconFitBiometrico,
  iconFlytefoam,
  iconFlyteFoamPropel,
  iconGel,
  iconGuideSole,
  iconIGs,
  iconLiteStruss,
  iconProtecao,
  iconTrusstic,
  genderSpecific,
} from '../../images/tecnologias'

const Tecnologias = () => {
  useEffect(() => {
    ditoTrack('acessou-tecnologias')
  }, [])

  const solados = [
    {
      title: 'GEL ™',
      description:
        'Tecnologia ícone projetada para melhorar a absorção de choque e proporcionar conforto, localizado em áreas estratégicas de impacto como o calcanhar e antepé.',
      imgProduto: gel,
      funcaoPrimaria: 'AMORTECIMENTO',
      obs: '',
      icon01: iconGel,
      icon02: iconConforto,
      icon03: '',
    },
    {
      title: 'AMPLIFOAM ™',
      description:
        'Espuma mais leve e macia que o eva convencional localizada na entressola projetada para proporcionar conforto e amortecimento de durabilidade.',
      imgProduto: amplifoam,
      funcaoPrimaria: 'AMORTECIMENTO',
      obs: '',
      icon01: iconAmplifoam,
      icon02: iconConforto,
      icon03: '',
    },
    {
      title: 'FLYTEFOAM ™',
      description:
        'Espuma leve e macia localizada na entressola projetada para suavizar o ciclo da passada dos corredores, proporcionando  conforto, amortecimento e durabilidade.',
      imgProduto: flytefoam,
      funcaoPrimaria: 'AMORTECIMENTO',
      obs: '',
      icon01: iconFlytefoam,
      icon02: iconConforto,
      icon03: '',
    },
    {
      title: 'FLYTEFOAM ™ \n PROPEL',
      description:
        'Espuma com alta durabilidade localizada na entressola que fornece maior impulsão, amortecimento e maciez.',
      imgProduto: flytefoamPropel,
      funcaoPrimaria: 'AMORTECIMENTO',
      obs: '',
      icon01: iconFlyteFoamPropel,
      icon02: iconConforto,
      icon03: '',
    },
    {
      title: 'FLYTEFOAM ™\n BLAST',
      description:
        'Proporciona leveza, retorno de energia e responsividade com amortecimento elástico e conforto, permitindo uma experiência de pouso mais suave na passada.',
      imgProduto: flytefoamBlast,
      funcaoPrimaria: 'AMORTECIMENTO',
      obs: '',
      icon01: iconFFBlast,
      icon02: iconConforto,
      icon03: '',
    },
    {
      title: 'FLYTEFOAM ™\n BLAST+',
      description:
        'Proporciona mais leveza, retorno de energia e responsividade com amortecimento elástico e conforto, permitindo uma experiência de pouso mais suave na passada.',
      imgProduto: flytefoamBlast02,
      funcaoPrimaria: 'AMORTECIMENTO',
      obs: '',
      icon01: iconFFBlastPlus,
      icon02: iconConforto,
      icon03: '',
    },
    {
      title: 'FLYTEFOAM ™ \n BLAST TURBO',
      description:
        'A entressola mais inovadora já criada pela marca. Feita em composto de nylon, ela proporciona altos níveis de amortecimento e retorno de energia inigualáveis.',
      imgProduto: flytefoamBlastTurbo,
      funcaoPrimaria: 'VELOCIDADE',
      obs: '',
      icon01: iconFFBlastTurbo,
      icon02: iconVelocidade,
      icon03: '',
    },
    {
      title: 'GUIDANCE\n LINE ™',
      description:
        'Proporciona um passe mais direcionado e consistente. Orienta efetivamente o movimento à medida em que muda seu centro de gravidade, fornecendo suporte completo.',
      imgProduto: guideDance,
      funcaoPrimaria: 'ESTABILIDADE',
      obs: '',
      icon01: iconProtecao,
      icon02: iconEstabilidade,
      icon03: '',
    },
    {
      title: 'GUIDESOLE ™',
      description:
        'O design de sola curvada ajuda os corredores a economizar energia, controlar a flexão do tornozelo e reduzir impacto.',
      imgProduto: guideSole,
      funcaoPrimaria: 'ECONOMIA DE ENERGIA',
      obs: '',
      icon01: iconGuideSole,
      icon02: iconEconomiaEnergia,
      icon03: '',
    },
    {
      title: 'DUOMAX ™',
      description:
        'Tecnologia de suporte com diferentes densidades localizadas na área medial da entressola. Duomax ajuda a evitar a deformação medial da entressola quando o pé se desloca devido à pronação do corredor, fornecendo mais estabilidade aumentando a eficiência e reduz lesões relacionadas à corrida.',
      imgProduto: duomax,
      funcaoPrimaria: 'ESTABILIDADE',
      obs: 'Solução da ASICS para a PRONADORES',
      icon01: iconDuomax,
      icon02: iconProtecao,
      icon03: iconEstabilidade,
    },
    {
      title: 'LITE TRUSS ™',
      description:
        'Esta tecnologia substitui a tradicional placa rígida localizada na região do medial. Ela traz maior estabilidade, leveza e evita a pronação excessiva. Fornecendo uma plataforma mais estável que aumenta a eficiência e pode reduzir lesões relacionadas à corrida.',
      imgProduto: litetruss,
      funcaoPrimaria: 'ESTABILIDADE',
      obs: 'Solução da ASICS para a PRONADORES',
      icon01: iconLiteStruss,
      icon02: iconProtecao,
      icon03: iconEstabilidade,
    },
    {
      title: 'TRUSSTIC SYSTEM ™',
      description:
        'Placa de suporte para o arco do pé projetada para reduzir torção e auxiliar  transições mais suaves.',
      imgProduto: trusstic,
      funcaoPrimaria: 'ESTABILIDADE',
      obs: '',
      icon01: iconTrusstic,
      icon02: iconProtecao,
      icon03: iconEstabilidade,
    },
    {
      title: 'AHAR ™',
      description:
        'Solado em borracha carbonada resistente ao desgaste, tornando a sola mais durável e flexível.',
      imgProduto: ahar,
      funcaoPrimaria: 'DURABILIDADE',
      obs: '',
      icon01: iconAhar,
      icon02: '',
      icon03: '',
    },
    {
      title: 'GENDER\n SPECIFIC ™',
      description:
        'Um grupo de tecnologias específicas são adaptados às necessidades biomecânicas masculinas e femininas para melhorar a experiência durante a corrida. Melhora a performance e entrega mais conforto.',
      imgProduto: genderSpecific,
      funcaoPrimaria: '',
      obs: '',
      icon01: iconFitBiometrico,
      icon02: '',
      icon03: '',
    },
    {
      title: 'IMPACT GUIDANCE\n SYSTEM (I.G.S.)™',
      description:
        'O I.G.S. É uma inovação técnica visando guiar o movimento do pé desde o contato do tornozelo até a propulsão. Seu objetivo é melhorar o desempenho, apoiando perfeitamente a anatomia do pé.',
      imgProduto: impactGuidance,
      funcaoPrimaria: '',
      obs: '',
      icon01: iconIGs,
      icon02: '',
      icon03: '',
    },
  ]

  return (
    <Layout>
      <SEO title="Tecnologias" />

      <BannerTopo />

      <S.WrapperCategorias>
        <S.ContainerCategorias>
          <S.TextWrapperLancamento>
            <h2>
              Conheça
              <strong>NOSSAS CATEGORIAS</strong>
            </h2>
          </S.TextWrapperLancamento>
        </S.ContainerCategorias>
        <S.ItemCategoria className="categoria01">
          <S.ContainerCategorias>
            <S.WrapCategoria>
              <S.DescricaoCategoria>
                <h3>AMORTECIMENTO</h3>
                <article>
                  Proporciona maciez, leveza <br /> e absorção de impacto.
                </article>
                <a
                  href="https://www.asics.com.br/Calcados?filtro_88=Amortecimento&utm_source=lab&utm_medium=lp&utm_campaign=crm-tecnologias"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  CONFIRA CATEGORIA COMPLETA
                </a>
              </S.DescricaoCategoria>
              <S.BgImagemProduto>
                <S.ImgProduto className="FLYTEFOAM"></S.ImgProduto>
              </S.BgImagemProduto>
            </S.WrapCategoria>
          </S.ContainerCategorias>
        </S.ItemCategoria>
        <S.ItemCategoria className="odd categoria02">
          <S.ContainerCategorias>
            <S.WrapCategoria className="odd">
              <S.DescricaoCategoria className="odd">
                <h3>ENERGIA</h3>
                <article>
                  Sensação de responsividade <br />e melhor performance.
                </article>
                <a
                  href="https://www.asics.com.br/Calcados?filtro_88=Energia&utm_source=lab&utm_medium=lp&utm_campaign=crm-tecnologias"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  CONFIRA CATEGORIA COMPLETA
                </a>
              </S.DescricaoCategoria>
              <S.BgImagemProduto>
                <S.ImgProduto className="glb2"></S.ImgProduto>
              </S.BgImagemProduto>
            </S.WrapCategoria>
          </S.ContainerCategorias>
        </S.ItemCategoria>
        <S.ItemCategoria className="categoria03">
          <S.ContainerCategorias>
            <S.WrapCategoria>
              <S.DescricaoCategoria>
                <h3>VELOCIDADE</h3>
                <article>
                  Novos recordes pessoais <br /> com maior leveza.
                </article>
                <a
                  href="https://www.asics.com.br/Calcados?filtro_88=Velocidade&utm_source=lab&utm_medium=lp&utm_campaign=crm-tecnologias"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  CONFIRA CATEGORIA COMPLETA
                </a>
              </S.DescricaoCategoria>
              <S.BgImagemProduto>
                <S.ImgProduto className="imgTenis03"></S.ImgProduto>
              </S.BgImagemProduto>
            </S.WrapCategoria>
          </S.ContainerCategorias>
        </S.ItemCategoria>
        <S.ItemCategoria className="odd categoria04">
          <S.ContainerCategorias>
            <S.WrapCategoria className="odd">
              <S.DescricaoCategoria className="odd">
                <h3>TRILHA</h3>
                <article>
                  Aderência, conforto <br />e segurança para trilhas.
                </article>
                <a
                  href="https://www.asics.com.br/calcados/trilha?utm_source=lab&utm_medium=lp&utm_campaign=crm-tecnologia"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  CONFIRA CATEGORIA COMPLETA
                </a>
              </S.DescricaoCategoria>
              <S.BgImagemProduto>
                <S.ImgProduto className="imgTenis04"></S.ImgProduto>
              </S.BgImagemProduto>
            </S.WrapCategoria>
          </S.ContainerCategorias>
        </S.ItemCategoria>
      </S.WrapperCategorias>

      <Features />

      <S.Solados>
        <S.TitleSolados>
          <h2>Solado</h2>
        </S.TitleSolados>
        <S.ContainerSolados>
          {solados.map((solado, index) => (
            <S.ItemSolado key={index}>
              <S.DescricaoSolado>
                <h3>{solado.title}</h3>
                <article>{solado.description}</article>
              </S.DescricaoSolado>
              <S.ImgProdutoSolado className={solado.imgProduto}>
                <img src={solado.imgProduto} alt={solado.title} />
              </S.ImgProdutoSolado>
              {solado.funcaoPrimaria && (
                <S.FuncaoPrimaria>
                  <h4>
                    <strong>Função Primária:</strong> {solado.funcaoPrimaria}
                  </h4>
                </S.FuncaoPrimaria>
              )}
              <S.ExtraInfo>
                <S.Icons>
                  {solado.icon01 && (
                    <S.Icon>
                      <img src={solado.icon01} alt={solado.title} />
                    </S.Icon>
                  )}
                  {solado.icon02 && (
                    <S.Icon>
                      <img src={solado.icon02} alt={solado.title} />
                    </S.Icon>
                  )}
                </S.Icons>
              </S.ExtraInfo>
            </S.ItemSolado>
          ))}
        </S.ContainerSolados>
      </S.Solados>

      <S.WrapperNewsletter id="newsLetterWrap">
        <S.ContentNewsletter>
          <h2>CADASTRE SEU E-MAIL PARA RECEBER OFERTAS E NOVIDADES</h2>

          <S.FormNewsletter>
            <FormNewsletter isLP />
          </S.FormNewsletter>
        </S.ContentNewsletter>
      </S.WrapperNewsletter>
    </Layout>
  )
}

export default Tecnologias
