import styled from 'styled-components'

import bgFeatures from '../../../assets/images/tecnologias/bg-features.jpg'
import bgFeaturesMobile from '../../../assets/images/tecnologias/bg-features-mobile.jpg'
import polygonFeature from '../../../assets/images/tecnologias/polygon-feature.png'

export const WrapperFeatures = styled.section`
  align-items: center;
  background-image: url(${bgFeatures});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  //height: 540px;
  justify-content: center;
  width: 100%;

  @media (max-width: 991px) {
    height: auto;
    padding: 100px 0 30px 0;
    background-image: url(${bgFeaturesMobile});
  }

  @media (min-width: 1024px) {
    padding: 100px 0 130px 0;
  }
  @media (min-width: 1700px) {
    padding: 100px 0 160px 0;
  }
`

export const FeaturesDesktop = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

export const wrapperMetaspeed = styled.div`
  position: relative;
  width: 1000px;

  @media (max-width: 991px) {
    width: 100%;
  }

  @media (min-width: 1700px) {
    width: 930px;
  }

  img {
    display: block;
    width: 410px;

    &.cabedal {
      margin-left: 115px;
      margin-top: 210px;
      @media (max-width: 767px) {
        margin-left: 0;
        margin-top: 0;
      }
      @media (min-width: 1700px) {
        width: 500px;
        margin-left: 0;
        margin-top: 290px;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      margin: 0;
    }
  }
`

export const ButtonFeature = styled.button`
  background-color: transparent;
  background-image: url(${polygonFeature});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
  height: 19px;
  width: 19px;
  position: absolute;
  right: 50%;
  text-indent: -9999px;
  top: 50%;
  z-index: 2;

  &.btn-feature1 {
    transform: translate(-57px, 64px);

    @media (min-width: 1700px) {
      transform: translate(-51px, 95px);
    }
  }

  &.btn-feature2 {
    transform: translate(-19px, 84px);

    @media (min-width: 1700px) {
      transform: translate(-19px, 116px);
    }
  }

  &.btn-feature3 {
    transform: translate(-19px, 111px);

    @media (min-width: 1700px) {
      transform: translate(-19px, 155px);
    }
  }

  &.btn-feature4 {
    transform: translate(-32px, 136px);

    @media (min-width: 1700px) {
      transform: translate(-36px, 175px);
    }
  }

  @media (max-width: 991px) {
    display: none;
  }
`

export const FeatureImg = styled.img`
  left: 50%;
  position: absolute;
  top: 50%;

  &.img-feature1 {
    transform: translate(-73px, -74px);
    width: 485px;

    @media (min-width: 1700px) {
      transform: translate(-64px, -83px);
    }
  }

  &.img-feature2 {
    transform: translate(-20px, 30px);
    width: 388px;

    @media (min-width: 1700px) {
      transform: translate(-35px, 36px);
    }
  }

  &.img-feature3 {
    transform: translate(-13px, 107px);
    width: 387px;

    @media (min-width: 1700px) {
      transform: translate(-25px, 142px);
    }
  }

  &.img-feature4 {
    transform: translate(-33px, 145px);
    width: 406px;

    @media (min-width: 1700px) {
      transform: translate(-48px, 181px);
    }
  }

  @media (min-width: 1700px) {
    width: 590px !important;
  }
`

export const FeaturesMobile = styled.div`
  margin: 30px 0;
  padding: 0 20px;
  width: 100%;

  img {
    margin: 15px 0;
    width: 100%;
    &.feature1Mobile {
      margin-bottom: -20px;
    }
  }

  @media (min-width: 991px) {
    display: none;
  }
`
