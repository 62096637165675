import styled from 'styled-components'
import {
  FLYTEFOAM,
  FLYTEFOAMOBILE,
  glb2,
  glb2Mobile,
  bgCategoria01,
  bgCategoria02,
  bgCategoria03,
  bgCategoria04,
  imgTenis03,
  imgTenis03Mobile,
  imgTenis04,
  imgTenis04Mobile,
  titleSolado,
} from '../../images/tecnologias'

export const WrapperCategorias = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 14px;
  width: 100%;
  background-color: #bfd3e8;
  margin: 0 auto;

  @media (max-width: 1700px) {
    padding: 15px 0;
    flex-direction: column;
    margin-top: 0;
    background-color: #bfd3e8;
  }
`

export const ContainerCategorias = styled.div`
  @media screen and (min-width: 1024px) and (max-width: 1699px) {
    max-width: 1000px;
    margin: 0 auto;
  }

  @media screen and (min-width: 1700px) {
    max-width: 1120px;
    margin: 0 auto;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`

export const WrapCategoria = styled.div`
  display: flex;
  align-items: center;
  gap: 135px;
  &.odd {
    flex-direction: row-reverse;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  @media (max-width: 767px) {
    gap: 0;
    flex-direction: column;
    width: 100%;
  }

  /*@media (min-width: 1700px) {
    gap: 302px;
  }*/
`

export const ItemCategoria = styled.section`
  display: flex;
  width: 100%;
  padding: 15px 0;
  position: relative;
  @media (min-width: 1024px) {
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 665px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top right;
      z-index: 0;
    }
  }

  @media (min-width: 1700px) {
    margin-bottom: 0;
    padding: 54px 0;
    &:after {
      height: 350px;
      width: 752px;
      background-size: contain;
    }
  }

  @media (max-width: 767px) {
    &:after {
      width: 100%;
      height: 60%;
      background-position: center;
      top: auto;
      bottom: -20px;
    }
  }

  &.odd {
    &:after {
      background-position: top left;
      left: 0;
    }
  }

  @media (min-width: 1024px) {
    &.categoria01 {
      &:after {
        background-image: url(${bgCategoria01});
      }
    }

    &.categoria02 {
      &:after {
        background-image: url(${bgCategoria02});
      }
    }

    &.categoria03 {
      &:after {
        background-image: url(${bgCategoria03});
      }
    }

    &.categoria04 {
      margin-bottom: 0;
      &:after {
        background-image: url(${bgCategoria04});
      }
    }
  }
`
export const DescricaoCategoria = styled.div`
  @media (max-width: 767px) {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
  }

  h3 {
    font-family: 'ASICSFont3.0-BoldItalic';
    color: var(--primary-color);
    font-size: 1.5rem;
    @media (min-width: 1700px) {
      font-size: 2rem;
    }
  }

  article {
    font-family: 'ASICSFont3.0-Regular';
    color: var(--primary-color);
    font-size: 1rem;
    @media (min-width: 1700px) {
      font-size: 1.3rem;
      line-height: 27px;
      margin-bottom: 5px;
    }
  }

  a {
    background: var(--primary-color);
    padding: 7px 30px 5px;
    border-radius: 50px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-family: 'ASICSFont3.0-Regular';
    font-size: 11px;
    text-decoration: none;
    &:hover {
      background: #b9411d;
      color: #fff;
    }

    @media (min-width: 1700px) {
      font-size: 13px;
    }
  }

  &.odd {
    text-align: right;
  }
`

export const TextWrapperLancamento = styled.div`
  //align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  padding: 30px 0;

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    padding: 15px;
    width: 100%;
  }

  h2 {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 100;
    font-style: italic;
    margin-bottom: 20px;
    line-height: 36px;
    text-transform: uppercase;

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      br {
        display: none;
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 25px;
    }
  }

  strong {
    color: var(--primary-color);
    font-size: 24px;
    font-style: italic;
    margin-left: 5px;
  }

  @media (max-width: 1700px) {
    padding: 15px 0;
    h2 {
      margin-bottom: 0;
    }
  }
`

export const BgImagemProduto = styled.div`
  display: flex;
  z-index: 7;
  @media (max-width: 767px) {
    width: 100%;
  }
`

export const ImgProduto = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;

  &.FLYTEFOAM {
    background-image: url(${FLYTEFOAM});
    @media (min-width: 991px) {
      width: 340px;
      height: 180px;
    }
    @media (max-width: 767px) {
      background-image: url(${FLYTEFOAMOBILE});
    }
  }
  &.glb2 {
    background-image: url(${glb2});
    @media (min-width: 991px) {
      width: 375px;
      height: 180px;
    }
    @media (max-width: 767px) {
      background-image: url(${glb2Mobile});
    }
  }

  &.imgTenis03 {
    background-image: url(${imgTenis03});
    @media (min-width: 991px) {
      width: 375px;
      height: 180px;
    }
    @media (max-width: 767px) {
      background-image: url(${imgTenis03Mobile});
    }
  }

  &.imgTenis04 {
    background-image: url(${imgTenis04});
    @media (min-width: 991px) {
      width: 375px;
      height: 180px;
    }
    @media (max-width: 767px) {
      background-image: url(${imgTenis04Mobile});
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    height: 180px;
    background-position: center;
    background-size: cover;
  }
`

export const Solados = styled.div`
  opacity: 1;
  padding: 30px 0;
  background: linear-gradient(0deg, #edf2f5, #bbd9f1 80%) no-repeat;
`

export const TitleSolados = styled.div`
  background-image: url(${titleSolado});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 170px;
  text-indent: -9999px;
  @media (max-width: 767px) {
    background-image: none;
    height: auto;
    text-indent: 0;
  }

  h2 {
    text-align: center;
    color: var(--primary-color);
    font-size: 34px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
`

export const ContainerSolados = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`

export const ItemSolado = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 12px 0px #00000026;
  color: var(--primary-color);
  background-color: #fff;
  border-radius: 10px;
  padding: 35px;
  width: 350px;
  justify-content: space-between;
  @media (max-width: 767px) {
    width: 90%;
    margin: 0 auto;
  }

  h3 {
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 1.9rem;
    white-space: pre-line;
    line-height: 28px;
    margin-bottom: 15px;
    min-height: 56px;
    @media (max-width: 767px) {
      min-height: auto;
    }
  }

  article {
    font-family: 'ASICSFont3.0-Regular';
    font-size: 1rem;
    line-height: 20px;
    min-height: 160px;
    @media (max-width: 767px) {
      min-height: auto;
    }
  }
`

export const ImgProdutoSolado = styled.div`
  margin: 15px 0;
  height: 160px;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    height: auto;
  }
`

export const DescricaoSolado = styled.div`
  opacity: 1;
`

export const FuncaoPrimaria = styled.div`
  background: #4a5fc4;
  border-radius: 14px;
  padding: 3px 15px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'ASICSFont3.0-Regular';
  text-align: center;
  margin-bottom: 15px;
  font-size: 12px;
`

export const ExtraInfo = styled.div`
  border: 1px solid #bfd3e8;
  border-radius: 15px;
  padding: 10px;
`

export const Icons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const Icon = styled.div`
  opacity: 1;
`

export const WrapperNewsletter = styled.section`
  padding: 70px 0 50px 0;
  width: 100%;

  @media (max-width: 991px) {
    padding: 0 15px 30px 15px;
  }
`

export const ContentNewsletter = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--primary-color);
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 28px;
    margin-bottom: 5px;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  img {
    position: absolute;
    right: 90px;
    top: 60px;
    width: 400px;

    @media (max-width: 991px) {
      display: none;
    }
  }
`

export const FormNewsletter = styled.div`
  margin-top: 30px;
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
  }
`
